import * as React from "react";
import { SimpleForm, TextInput, Create } from "react-admin";
let styled = {
  width: "500px",
};
export const OrderCreate = (props) => (
  <Create title="Create new vet" warnWhenUnsavedChanges {...props}>
    <SimpleForm>
      <TextInput style={styled} source="name" />
      <TextInput style={styled} source="customer" />
      <TextInput style={styled} source="phone" />
      <TextInput style={styled} source="shipping_address" />
      <TextInput style={styled} source="quantity" />
      <TextInput style={styled} source="totalPrice" />
    </SimpleForm>
  </Create>
);
