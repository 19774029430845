import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl=process.env.REACT_APP_BASE_API_URL;
export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  // add my own headers here
  // options.headers.set("Access-Control-Expose-Headers", "Content-Range");
  // options.headers.set("Content-Range", "users 0-24/319");
  const { accessToken } = JSON.parse(localStorage.getItem("auth"));
  options.headers.set("Authorization", `Bearer ${accessToken}`);
  return fetchUtils.fetchJson(url, options);
};

// Here is how this Data Provider maps react-admin calls to API calls
export const CustomDataProvider = {
  getList: (resource, params) => {
    // console.log("params",params.filter.status);
    const { total } = resource;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    let roleToWarehouseMapping = {
      admin:
        "where%5Bwarehouse%5D[in]=Tardeo&where%5Bwarehouse%5D[in]=Goregaon&where%5Bwarehouse%5D[in]=Thane&where%5Bwarehouse%5D[in]=NaviMumbai&where%5Bwarehouse%5D[in]=Others",
      tardeo_manager: "where%5Bwarehouse%5D[in]=Tardeo",
      goregaon_manager: "where%5Bwarehouse%5D[in]=Goregaon",
      thane_manager: "where%5Bwarehouse%5D[in]=Thane",
      navimumbai_manager: "where%5Bwarehouse%5D[in]=NaviMumbai",
      others_manager: "where%5Bwarehouse%5D[in]=Others"
    };
    let localAuth = JSON.parse(localStorage.getItem("auth")) || [];
    let currentRole = localAuth.roles[0]
    // if (!localAuth.roles) {
    //   let permissionsRole = localStorage.getItem("roles" || "");
    //   currentRole = permissionsRole
    // } else {
    //   currentRole = "admin";
    // }
 
    const query = {
      skip: (page - 1) * perPage,
      take: perPage,
    };

    // let baseQuery = stringify(query);
    // if (params.filter && params.filter.q && params.filter.q.length > 3) {
    //   baseQuery = `&where%5Border_number%5D[contains]=${params.filter.q}` ||
    //   `&where%5Bcustomer%5D[contains]=${params.filter.q}`;
    // }

    let baseQuery = stringify(query);
    // if (params.filter && params.filter.q ) {
    //   baseQuery = `&where%5Border_number%5D[contains]=${params.filter.q}`;
    // }

    if (params.filter && params.filter.q) {
      let queryValue = params.filter.q.toString(); // Convert to string
      if (!isNaN(queryValue) && Number(queryValue) > 2) {
        baseQuery = `&where%5Border_number%5D[contains]=${queryValue}`;
      } else {
        // Convert queryValue to lowercase for a case-insensitive search
        queryValue = queryValue.toLowerCase();
        // Capitalize the first letter
        queryValue = queryValue.charAt(0).toUpperCase() + queryValue.substr(1);
    
        baseQuery = `&where%5Bcustomer_name%5D[contains]=${queryValue}`;
      }
    }

    // status filter
    if (
      params.filter &&
      params.filter.status &&
      params.filter.status.length > 0
    ) {
      baseQuery = baseQuery + `&${params.filter.status}`;
    }
    // warehouse filter
    if (
      params.filter &&
      params.filter.warehouse &&
      params.filter.warehouse.length > 0
    ) {
      baseQuery = baseQuery + `&${params.filter.warehouse}`;
    }
    // date filter
    if (
      params.filter &&
      params.filter.startDate &&
      params.filter.startDate.length > 0
    ) {
      baseQuery =
        baseQuery + `&where%5Bprocessed_at%5D[gte]=${params.filter.startDate}`;
    }
    if (
      params.filter &&
      params.filter.endDate &&
      params.filter.endDate.length > 0
    ) {
      baseQuery =
        baseQuery + `&where%5Bprocessed_at%5D[lte]=${params.filter.endDate}`;
    }

    // before baseQuery
    let url1;
    if (typeof resource === "string" && resource.search("orders") === -1) {
      url1 = `${apiUrl}/${resource}?${baseQuery}&orderBy%5BcreatedAt%5D=desc`;
    } else {
      url1 = `${apiUrl}/${resource}?${roleToWarehouseMapping[currentRole]}&${baseQuery}&orderBy%5Bprocessed_at%5D=desc`;
    }
    return httpClient(url1).then((response) => {
      const length = response.json?.length;
      const total = resource === "api/orders" ? 1000000 : length;
      return { data: response.json, total };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    let queryString = "";
    if (params && params.ids && params.ids.length > 0) {
      for (let i = 0; i < params.ids[0].length; i++) {
        // console.log("Before Log",params.ids[0][i]);
        let q = `where%5Bid%5D[in]=${params.ids[0][i]}`;
        queryString = queryString + q;
        if (i !== params.ids[0].length - 1) {
          queryString = queryString + "&";
        }
      }
      queryString = queryString + "&orderBy%5Bprocessed_at%5D=desc";
    }

    // const url = `${apiUrl}/${resource}?where%5Bid%5D[in]=claflcglw01128upakyy7djur&where%5Bid%5D[in]=claflbin800988upabefyvtyl&where%5Bid%5D[in]=clafl8g5000358upas7y8llfb`;
    const url = `${apiUrl}/${resource}?${queryString}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json, total: json?.length })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}?${params.id}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};
