import { Admin, Resource } from 'react-admin';
import { BoardingCenterCreate } from '../boardingCenters/BoardingCenterCreate';
import { BoardingCenterEdit } from '../boardingCenters/BoardingCenterEdit';
import { BoardingCenterList } from '../boardingCenters/BoardingCenterList';
import { BreederCreate } from '../breeders/BreederCreate';
import { BreederEdit } from '../breeders/BreederEdit';
import { BreederList } from '../breeders/BreederList';
import { GrommerCreate } from '../groomers/GrommerCreate';
import { GrommerList } from '../groomers/GrommerList';
import { GrommerEdit } from '../groomers/GroomerEdit';
import { OrderCreate } from '../orders/OrderCreate';
import  {OrderEdit}  from '../orders/OrderEdit';
import { OrderList } from '../orders/OrderList';
import { CustomDataProvider } from '../provider/CustumDataProvider';
import { RecreationCenterCreate } from '../recreationCenters/RecreationCenterCreate';
import { RecreationCenterEdit } from '../recreationCenters/RecreationCenterEdit';
import { RecreationCenterList } from '../recreationCenters/RecreationCenterList';
import { TrainerCreate } from '../trainers/TrainerCreate';
import { TrainerEdit } from '../trainers/TrainerEdit';
import { TrainerList } from '../trainers/TrainerList';
import { UserCreate } from '../users/UserCreate';
import { UserEdit } from '../users/UserEdit';
import { UserList } from '../users/UserList';
import { VetCreate } from '../vets/VetCreate';
import { VetEdit } from '../vets/VetEdit';
import { VetList } from '../vets/VetList';
import PaidIcon from '@mui/icons-material/Paid';
import HubIcon from '@mui/icons-material/Hub';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import PetsIcon from '@mui/icons-material/Pets';
import UserIcon from '@mui/icons-material/Group';
import authProvider from '../authProvider/authProvider';
import { defaultTheme } from 'react-admin';
import indigo from '@mui/material/colors/indigo';
import yellow from '@mui/material/colors/yellow';
import red from '@mui/material/colors/red';
import MyLoginPage from '../pages/MyLoginPage';
import MyLayout from '../components/AppBar/MyLayout';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FileReader from '../inventory/FileReader';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Stories from '../stories/Stories';
import { Box, CircularProgress } from '@mui/material';

const myTheme = {
    ...defaultTheme,
    palette: {
        primary: indigo,
        secondary: yellow,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
   
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
};

const Ready = () => (
        <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center" }}>
            <CircularProgress />
        </Box>
)
export const StoreAdmin = () => {
    const [roleData, setRoleData] = useState([])
    const { roles, id, accessToken } = JSON.parse(localStorage.getItem("auth")) || [];
    // console.log("roles,id",roles,id);
    
    const rolesAPI = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/users/${id}`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`
                  }
            }
            );
            setRoleData(response?.data);
        }
        catch (error) {
            console.log(error);
        }
    };
    useEffect(() =>{
        if(accessToken){
            rolesAPI()
        }
    },[accessToken])
    // role and roleData using other users
      const role =(roleData?.roles)
      // if(!role) return null
    //   console.log("mainRole",role);
    //   localStorage.setItem("roles", role)
    //   let permissionsRole = localStorage.getItem("roles" || "")
    //   let permissions = permissionsRole?.slice(2,-2)
    //   console.log("role permissions",roles)
       
  return ( 
    <Admin ready={Ready} basename="/admin" layout={MyLayout} loginPage={MyLoginPage} theme={myTheme} dataProvider={CustomDataProvider} authProvider={authProvider}>
        <>
                {/* { roles?.includes('admin') ? <Resource name="api/vets" options={{label:"Vets"}} list={VetList} edit={VetEdit} create={VetCreate} icon={PetsIcon}  />: null   } */}
                { roles?.includes('admin') ? <Resource name="api/orders" options={{label:"Orders"}} list={OrderList} edit={OrderEdit} create={OrderCreate} icon={PaidIcon}/> : null   }
                { roles?.includes("goregaon_manager") ? <Resource name="api/orders" options={{label:"Orders"}} list={OrderList} edit={OrderEdit} create={OrderCreate} icon={PaidIcon}/> : null   }
                { roles?.includes("tardeo_manager") ? <Resource name="api/orders" options={{label:"Orders"}} list={OrderList} edit={OrderEdit} create={OrderCreate} icon={PaidIcon}/> : null   }
                { roles?.includes("navimumbai_manager") ? <Resource name="api/orders" options={{label:"Orders"}} list={OrderList} edit={OrderEdit} create={OrderCreate} icon={PaidIcon}/> : null   }
                { roles?.includes("thane_manager") ? <Resource name="api/orders" options={{label:"Orders"}} list={OrderList} edit={OrderEdit} create={OrderCreate} icon={PaidIcon}/> : null   }
                { roles?.includes("others_manager") ? <Resource name="api/orders" options={{label:"Orders"}} list={OrderList} edit={OrderEdit} create={OrderCreate} icon={PaidIcon}/> : null   }
                { roles?.includes('admin') ? <Resource name="api/users" options={{label:"Users"}} list={UserList} icon={UserIcon} edit={UserEdit} create={UserCreate}  />: null   }
                {/* { roles?.includes('admin') ? <Resource name="api/breeders" options={{label:"Breeders"}} list={BreederList} edit={BreederEdit} create={BreederCreate} icon={ViewTimelineIcon} />: null   }
                { permissions === "contentmanager" ? <Resource name="api/breeders" options={{label:"Breeders"}} list={BreederList} edit={BreederEdit} create={BreederCreate} icon={ViewTimelineIcon} />: null   }
                { roles?.includes('admin') ? <Resource name="api/groomers" options={{label:"Groomers"}} list={GrommerList} edit={GrommerEdit} create={GrommerCreate} icon={HomeRepairServiceIcon}  />: null   }
                { permissions === "contentmanager" ? <Resource name="api/groomers" options={{label:"Groomers"}} list={GrommerList} edit={GrommerEdit} create={GrommerCreate} icon={HomeRepairServiceIcon}  />: null   }
                { roles?.includes('admin') ? <Resource name="api/trainers" options={{label:"Trainers"}} list={TrainerList} edit={TrainerEdit} create={TrainerCreate} icon={HubIcon} />: null   }
                { permissions === "contentmanager" ? <Resource name="api/trainers" options={{label:"Trainers"}} list={TrainerList} edit={TrainerEdit} create={TrainerCreate} icon={HubIcon} />: null   }
                { roles?.includes('admin') ? <Resource name="api/recreationCenters" options={{label:"Recreation Centers"}} list={RecreationCenterList} edit={RecreationCenterEdit} create={RecreationCenterCreate} icon={AssignmentTurnedInIcon}  />: null   }
                { permissions === "contentmanager" ? <Resource name="api/recreationCenters" options={{label:"Recreation Centers"}} list={RecreationCenterList} edit={RecreationCenterEdit} create={RecreationCenterCreate} icon={AssignmentTurnedInIcon}  />: null   } */}
                {/* { roles?.includes('admin') ? <Resource name="api/boardingCenters" options={{label:"Boarding Centers"}} list={BoardingCenterList} edit={BoardingCenterEdit} create={BoardingCenterCreate} icon={FilterFramesIcon}  />: null   }
                { permissions === "contentmanager" ? <Resource name="api/boardingCenters" options={{label:"Boarding Centers"}} list={BoardingCenterList} edit={BoardingCenterEdit} create={BoardingCenterCreate} icon={FilterFramesIcon}  />: null   } */}
                { roles?.includes('admin') ? <Resource name="files/inventory-uploads" options={{label:"Inventory Uploads"}} list={<FileReader />}  icon={AddBusinessIcon}  />: null  }
                { roles?.includes("inventory_manager") ? <Resource name="files/inventory-uploads" options={{label:"Inventory Uploads"}} list={<FileReader />}  icon={AddBusinessIcon}  />: null  }
                {/* { roles?.includes('admin') ? <Resource name="files/stories" options={{label:"Stories"}} list={<Stories />}  icon={AutoStoriesIcon}  />: null  }
                { permissions === "contentmanager" ? <Resource name="files/stories" options={{label:"Stories"}} list={<Stories />}  icon={AutoStoriesIcon}  />: null  } */}
            </>
    </Admin>
  )
}
