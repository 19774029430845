import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import Empty from "../empty/Empty";

export const UserList = () => {
  // const { data: users } = useGetList("api/users");
  // console.log("usersusers", JSON.parse(users.roles).join(", "));
  // console.log("users", users && users.map(role => role.roles));

  return (
    <List empty={<Empty />} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="username" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="roles[0]" label="Roles" />
      </Datagrid>
    </List>
  );
};
