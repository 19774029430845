import { Button } from "@mui/material";
import React from "react";
import { SimpleForm, TextInput, Edit, useRedirect, TopToolbar, PasswordInput, SelectInput } from 'react-admin';

const UserName = ({ record }) => {
    return <span>User {record ? `"${record.usename}"` : ""}</span>;
  };
  
const UserEditActions = ({ basePath, data, resource }) => {
    const redirect = useRedirect();
    return (
      <TopToolbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        {/* Add your custom actions */}
        <Button color="primary" onClick={() => redirect("/admin/api/users")}>
          Back
        </Button>
      </TopToolbar>
    );
  };
const roles =  {
    admin: ["admin"],
    tardeomanager:["tardeo_manager"],
    goregaonmanager:["goregaon_manager"],
    contentmanager:["content_manager"],
    inventorymanager:["inventory_manager"],
    navimumbaimanager:["navimumbai_manager"],
    thanemanager:["thane_manager"],   
    othersmanger:["others_manager"],   
}

export const UserEdit = (props) => (
    <Edit 
        actions={<UserEditActions />}
        title={<UserName />}
        successMessage={"Successfully edit user"}
        {...props}
        >
        <SimpleForm>
        <SelectInput
        label="Select Role"
        source="roles"
        fullWidth
        choices={[
          { id: roles.admin, name: "Admin" },
          { id: roles.tardeomanager, name: "Tardeomanager" },
          { id: roles.goregaonmanager, name: "Goregaonmanager" },
          { id: roles.contentmanager, name: "Contentmanager" },
          { id: roles.inventorymanager, name: "Inventorymanager" },
          { id: roles.navimumbaimanager, name: "Navimumbaimanager" },
          { id: roles.thanemanager, name: "Thanemanager" },
          { id: roles.othersmanger, name: "Othersmanager" },
        ]}
      />
      <TextInput fullWidth source="username" />
      <TextInput fullWidth source="firstName" />
      <TextInput fullWidth source="lastName" />
      <PasswordInput fullWidth source="password" />

        </SimpleForm>
    </Edit>
);