import * as React from "react";
import { useState } from "react";
import { CustomDataProvider } from "../provider/CustumDataProvider";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  TopToolbar,
  DateField,
  SelectInput,
  DateInput,
  useInfiniteGetList
} from "react-admin";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import ComponentToPrint, { CsvFile } from "./ComponentToPrint";
import { useEffect } from "react";
import Empty from "../empty/Empty";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";

const { roles } = JSON.parse(localStorage.getItem("auth")) || [];

const Comp = () => {
  return roles?.includes("admin") ? (
    <SelectInput
      alwaysOn
      source="warehouse"
      label="Warehouse"
      choices={[
        { id: "where%5Bwarehouse%5D[equals]=Tardeo", name: "Tardeo" },
        { id: "where%5Bwarehouse%5D[equals]=Goregaon", name: "Goregaon" },
        { id: "where%5Bwarehouse%5D[equals]=Thane", name: "Thane" },
        { id: "where%5Bwarehouse%5D[equals]=NaviMumbai", name: "NaviMumbai" },
        { id: "where%5Bwarehouse%5D[equals]=Others", name: "Others" },
      ]}
    />
  ) : (
    ""
  );
};

const OrderBulkActionButtons = (props) => {
  const [exportData, setExportData] = useState([]);
  const componentRef = useRef();

  // Create a variable to store export data before the click
  const initialExportData = useMemo(() => [], []);
  // console.log("outside exportData", exportData);

  const handleClick = () => {
    if (props.selectedIds.length > 0) {
      CustomDataProvider.getMany("api/orders", {
        ids: [props.selectedIds],
      }).then(function (response) {
        setExportData(response?.data);
        // console.log("inside exportData", response?.data);
      });
    }
  };

  useEffect(() => {
    // console.log("Order List exportData", exportData);
    // console.log("Order List Selected IDs:", props.selectedIds);
    handleClick();
  }, [props.selectedIds]);

  return (
    <React.Fragment>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ComponentToPrint ref={componentRef} printData={exportData} />
        <ReactToPrint
          trigger={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "20px",
              }}
            >
              <ArrowDownwardIcon />
              <Button
                style={{
                  cursor: "pointer",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  // Set the exportData from the initialExportData variable
                  setExportData(initialExportData);
                  handleClick();
                }}
              >
                {exportData?.length === 0 ? "Download" : "Download"}
              </Button>
            </div>
          )}
          content={() => componentRef.current}
        />
        <CsvFile orders={exportData} />
      </div>
    </React.Fragment>
  );
};

export const OrderList = (props) => {
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [debouncedStatus, setDebouncedStatus] = useState("");
  const [debouncedWarehouse, setDebouncedWarehouse] = useState("");
  const [debouncedStartDate, setDebouncedStartDate] = useState("");
  const [debouncedEndDate, setDebouncedEndDate] = useState("");
  const navigate = useNavigate()
  const handleRowClick = (id) => {
    const editUrl = `/admin/api/orders/${id}`; 
    // Open the edit view in a new tab
    window.open(editUrl, '_blank');
  };
  const applyAllFilters = () => {
    setDebouncedStatus(status);
    setDebouncedWarehouse(warehouse);
    setDebouncedStartDate(startDate);
    setDebouncedEndDate(endDate);
    setDebouncedSearchText(searchText);
  };
  const clearAllFilters = () => {
    setDebouncedSearchText('');
    setDebouncedStatus('');
    setDebouncedWarehouse('');
    setDebouncedStartDate('');
    setDebouncedEndDate('');
    setSearchText('')
    setStatus('')
    setWarehouse('')
    setEndDate('')
    setStartDate('')
    navigate("/admin/api/orders")
    navigate("/admin/api/orders?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=10&sort=id")
    console.log('Filters cleared.');
  };
  const ListActions = () => (
    <TopToolbar>
       <div style={{display:'flex', gap:'10px', marginBottom:'10px'}}>
          <Button style={{  fontSize:'12px'}} onClick={applyAllFilters} variant="contained">Apply Filters</Button>
          <Button style={{ fontSize:'12px'}} onClick={clearAllFilters} variant="contained">Clear Filters</Button>
        </div>
    </TopToolbar>
  );
  const orderFilters = [
    <TextInput
      label="Search by shopify order number or name"
      source="q"
      alwaysOn
      style={{ width: "310px" }}
      onChange={(e) => setSearchText(e.target.value)}
      value={searchText}
    />,

   <SelectInput
   alwaysOn
   source="status"
   label="Status"
   choices={[
     { id: "where%5Bstatus%5D[equals]=pending", name: "Pending" },
     {
       id: "where%5Bstatus%5D[equals]=product_missing",
       name: "Product missing",
     },
     { id: "where%5Bstatus%5D[equals]=billed", name: "Billed" },
     { id: "where%5Bstatus%5D[equals]=dispatch", name: "Ready to Dispatch" },
     { id: "where%5Bstatus%5D[equals]=dispatched", name: "Dispatched" },
     { id: "where%5Bstatus%5D[equals]=delivered", name: "Delivered" },
     { id: "where%5Bstatus%5D[equals]=return", name: "Return" },
     { id: "where%5Bstatus%5D[equals]=cancelled", name: "Cancelled" },
     { id: "where%5Bstatus%5D[equals]=closed", name: "Closed" },
   ]}
   value={status}
   onChange={(e) => setStatus(e.target.value)}
 />,

    <SelectInput
      alwaysOn
      source="warehouse"
      label="Warehouse"
      choices={[
        { id: "where%5Bwarehouse%5D[equals]=Tardeo", name: "Tardeo" },
        { id: "where%5Bwarehouse%5D[equals]=Goregaon", name: "Goregaon" },
        { id: "where%5Bwarehouse%5D[equals]=Thane", name: "Thane" },
        { id: "where%5Bwarehouse%5D[equals]=NaviMumbai", name: "NaviMumbai" },
        { id: "where%5Bwarehouse%5D[equals]=Others", name: "Others" },
      ]}
      value={warehouse}
      onChange={(e) => setWarehouse(e.target.value)}
    />,

    <DateInput
      onChange={(e) => setStartDate(e.target.value)}
      source="startDate"
      alwaysOn
      label="Start date"
      value={startDate}
      style={{ width: "140px" }}
    />,
    <DateInput
      onChange={(e) => setEndDate(e.target.value)}
      source="endDate"
      alwaysOn
      label="End Date"
      value={endDate}
      style={{ width: "140px" }}
    />,  
   
  ];
  
  // useEffect(() => {
    // const timerId = setTimeout(() => {
    //   setDebouncedSearchText(searchText);
    //   // setDebouncedStatus(status);
    //   // setDebouncedWarehouse(warehouse);
    //   // setDebouncedStartDate(startDate);
    //   // setDebouncedEndDate(endDate);
    // }, 1000);

    // return () => {
    //   clearTimeout(timerId);
    // };
  // }, [searchText]);
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const q = searchParams.get("q") || "";
    const status = searchParams.get("status") || "";
    const warehouse = searchParams.get("warehouse") || "";
    const startDate = searchParams.get("startDate") || "";
    const endDate = searchParams.get("endDate") || "";
    setDebouncedSearchText(q);
    setDebouncedStatus(status);
    setDebouncedWarehouse(warehouse);
    setDebouncedStartDate(startDate);
    setDebouncedEndDate(endDate);
  }, []);

  const {
    data,
    total,
    isLoading,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetList("api/orders", {
    pagination: { page: 1, perPage: 25 },
    filter: {
      q: debouncedSearchText,
      status: debouncedStatus,
      warehouse: debouncedWarehouse,
      startDate: debouncedStartDate,
      endDate: debouncedEndDate,
    },
  });

  if (isLoading) {
    return (
      <p style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </p>
    );
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <>

      <List
        dense
        filters={orderFilters}
        actions={<ListActions />}
        exporter={false}
        pagination={false}
      >
        <Datagrid
          data={data?.pages.flatMap((page) => page.data)}
          empty={<Empty />}
          total={total}
          rowClick={(id, event) => {
            handleRowClick(id);
          }}
          bulkActionButtons={<OrderBulkActionButtons />}
        >
          <TextField source="order_number" label="Order Number" />
          <TextField source="warehouse" label="Store" />
          <TextField source="shipping_address.name" label="Ship-To-Name" />
          <DateField source="processed_at" label="Purchase date" />
          <TextField source="total_price" label="Grand Total ₹" />
          <TextField source="status" label="Status" />
          <TextField source="customer.posBillNumber" label="POS Bill Number" />
          <TextField source="customer.warehouseComment" label="Comment" />
        </Datagrid>
      </List>
      {hasNextPage && (
        <Button
          style={{ marginTop: "20px", marginBottom: "20px" }}
          variant="contained"
          disabled={isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          Load More
        </Button>
      )}
    </>
  );
};
