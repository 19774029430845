import { Typography } from "@mui/material";
import React from "react";
import { RadioButtonGroupInput, TextInput } from "react-admin";

const OrderEditConfirmModal = ({
  chooseComment,
  change,
  fulfillmentServiceFunc,
  trackingUrlFunc,
  posBillNumberFunc,
  warehouse
}) => {
  const showTrackingUrlField = warehouse === "Others" && change === "dispatched";
  return (
    <div>
      <Typography component="legend" fontWeight="400" fontSize="18px">
        Are you sure you want to update the status of the order?
      </Typography>
      <TextInput
        source="comment"
        multiline
        onChange={(e) => chooseComment(e.target.value)}
        label="Add Comment"
        fullWidth
      />
      {/* dtdc, shiprocket, self */}
      {change === "dispatch" ? (
        <RadioButtonGroupInput
          source="carrier"
          label="Fullfilment Service"
          onChange={(e) => fulfillmentServiceFunc(e.target.value)}
          defaultValue='dtdc'
          choices={[
            { id: "dtdc", name: "DTDC" },
            { id: "shiprocket", name: "Shiprocket" },
          ]}
        />
      ) : (
        ""
      )}
      {showTrackingUrlField && (
        <TextInput
          source="customer.trackingUrl"
          onChange={(e) => trackingUrlFunc(e.target.value)}
          label="Tracking ID"
          fullWidth
        />
      )}

      {change === "billed" && (
        <TextInput
          required
          source="customer.posBillNumber"
          onChange={(e) => posBillNumberFunc(e.target.value)}
          label="POS Bill Number"
          fullWidth
        />
      )}
    </div>
  );
};

export default OrderEditConfirmModal;
