import axios from "axios";
import React, { useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  useNotify,
  useRecordContext,
} from "react-admin";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CustomDataProvider } from "../provider/CustumDataProvider";
import Empty from "../empty/Empty";
import { Pending } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FileReader = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [type, setType] = useState("");
  const { username } = JSON.parse(localStorage.getItem("auth")) || [];
  const notify = useNotify();
  let formData = new FormData();
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState("");
  const [load, setLoad] = useState(false);

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = async () => {
    formData.append("file", file);
    setLoading(<CircularProgress />)
    setLoad(true)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/files/inventory-upload?username=${username}&type=${type}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      
      if (res.data) {
        notify("file succesfully uploaded!");
      } else {
        notify("failed!");
      }
    } catch (error) {
      console.log(error);
    }
    handleClose();
    setLoading("")
    setLoad(false)

  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"50px"  }}>
        <div>
          <Button
            onClick={() => {
              setType("retail");
              handleOpen();
            }}
            variant="contained"
            id='retail'
          >
            Upload Retail Inventory
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <h1 style={{ textAlign: "center" }}>Upload File</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                  }}
                >
                  <input type="file" onChange={onFileChange} name="file" />
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    id='retail'
                  >
                   {!load ? "Submit" : "Uploading"}
                  </Button>
                  <p style={{ paddingLeft:"5px"}}>{loading}</p>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
        {/* Other file upload */}
        <div style={{ marginLeft: "10px"}}>
          <Button
            onClick={() => {
              setType("medical");
              handleOpen();
            }}
            variant="contained"
            id='medical'
          >
            Upload Medical Inventory
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <h1 style={{ textAlign: "center" }}>Upload File</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                  }}
                >
                  <input type="file" onChange={onFileChange} name="file" />
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    id='medical'
                  >
                     {!load ? "Submit" : "Uploading"}
                  </Button>
                  <p style={{ paddingLeft:"5px"}}>{loading}</p>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>

      <div style={{marginTop:'-10px'}}>
        <List exporter={false} empty={<Empty />} perPage={25}>
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="name" label="Name" />
            <TextField source="lastModified" label="Last Modified" />
            <TextField source="stat.metaData.username" label="Uploaded By" />
            <TextField source="stat.metaData.type" label="Type" />
            <TextField source="tags[0].Value" label="Tag" />
          </Datagrid>
        </List>
      </div>
    </>
  );
};

export default FileReader;
