import { Box, Typography } from "@mui/material";

const Empty = () => (
    <Box textAlign="center" m={1}>
        <Typography variant="h5" paragraph>
            No data available
        </Typography>
    </Box>
);

export default Empty;