import {
  Box,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLogin, useNotify} from "react-admin";
import { useNavigate } from "react-router-dom";
import logo from "../media/shakehandsLogo.png";
const MyLoginPage = ({ theme }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate()
  const { roles } = JSON.parse(localStorage.getItem("auth")) || [];
  
  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password })
    .then(() =>{
      if(roles[0] === "inventory_manager"){
        navigate("/files/inventory-uploads")
      }else{
        navigate("/admin/api/orders")
      }
    })
    .catch(() =>
      notify("Invalid username or password")
    );
  };
  // useEffect(() => {
  //   return () => window.location.reload();
  // },[]);
  useEffect(() => {
    const reloadPage = () => {
      window.location.reload();
    };
  
    return () => {
      reloadPage();
    };
  }, []);
  return (
    <Box
      style={{
        display: "flex",
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px",
        boxSizing: "border-box",
        padding: "0px",
      }}
    >
      <Grid>
        <Card style={{ maxWidth: 450, padding: "20px 5px", margin: "0 auto" }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              textAlign="center"
              fontWeight="600"
            >
              ShakeHands Admin
            </Typography>
            <Typography margin="20px" textAlign="center">
              <img width="280px" height="80px" src={logo} alt="img"></img>
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    type="text"
                    placeholder="Username*"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password*"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    sign in
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};

export default MyLoginPage;
