import * as React from "react";
import { TextField } from "react-admin";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./orderedit.css";
import moment from "moment";

const OrderEditPdfForm = ({ order, tableData, componentRef }) => {
  return (
    <>
      {/* Form */}
      <div style={{ display: "none" }}>
        <div ref={componentRef} style={{ padding: "2rem", fontSize: "16px" }}>
          {/* Order Details start */}
          <Box sx={{ maxWidth: 1000 }} style={{ border: "1px solid" }}>
            <Box
              style={{
                backgroundColor: "#6b6969",
                color: "white",
                padding: "10px",
              }}
            >
              <Typography>
                Order # <TextField source="order_id" />
              </Typography>
              <Typography>
                Order Date:{" "}
                <span>
                  {moment(order.createdAt).utc().format("DD/MM/YYYY")}
                </span>
              </Typography>
            </Box>
            <Box>
              {/* Inside Row Box */}
              <Box width="100%" display="flex" flexDirection="row">
                {/* 1st Row */}
                <Box width="100%" display="flex" flexDirection="column">
                  <Typography
                    component="legend"
                    // marginTop="20px"
                    padding="10px"
                    fontWeight="500"
                    marginBottom="10px"
                    // border="1px solid"
                    borderBottom="1px solid"
                    borderRight="2px solid"
                    fontSize="18px"
                    backgroundColor="#dddddd"
                  >
                    Sold to:
                  </Typography>
                  <Box style={{ padding: "10px" }}>
                    <TextField
                      marginBottom="4px"
                      source="customer.default_address.name"
                      // fontWeight="500"
                      fontSize="16px"
                    />
                    <TextField
                      marginBottom="4px"
                      source="customer.default_address.address1"
                      // fontWeight="500"
                      fontSize="16px"
                    />
                    <span>
                      {" "}
                      <TextField
                        marginBottom="4px"
                        source="customer.default_address.city"
                        // fontWeight="500"
                        fontSize="16px"
                      />
                      <span>, </span>
                      <TextField
                        source="customer.default_address.province"
                        // fontWeight="500"
                        fontSize="16px"
                      />
                      <span> </span>
                      <TextField
                        source="customer.default_address.zip"
                        // fontWeight="500"
                        fontSize="16px"
                      />
                    </span>
                    <TextField
                      marginBottom="4px"
                      source="customer.default_address.country"
                      // fontWeight="500"
                      fontSize="16px"
                    />
                    <span>
                      Tel:{" "}
                      <TextField
                        source="customer.default_address.phone"
                        // fontWeight="500"
                        fontSize="16px"
                      />
                    </span>
                  </Box>
                </Box>
                {/* 2nd Row */}
                <Box width="100%" display="flex" flexDirection="column">
                  <Typography
                    component="legend"
                    padding="10px"
                    fontWeight="500"
                    marginBottom="10px"
                    borderBottom="1px solid"
                    // borderTop="1px solid"
                    fontSize="18px"
                    backgroundColor="#dddddd"
                  >
                    Ship to:
                  </Typography>
                  <Box style={{ padding: "10px" }}>
                    <TextField
                      marginBottom="4px"
                      source="shipping_address.name"
                      fontSize="16px"
                    />
                    <TextField
                      marginBottom="4px"
                      source="shipping_address.address1"
                      fontSize="16px"
                    />
                    <span>
                      {" "}
                      <TextField
                        marginBottom="4px"
                        source="shipping_address.city"
                        fontSize="16px"
                      />
                      <span>, </span>
                      <TextField
                        source="shipping_address.province"
                        fontSize="16px"
                      />
                      <span> </span>
                      <TextField
                        source="shipping_address.zip"
                        fontSize="16px"
                      />
                    </span>
                    <TextField
                      marginBottom="4px"
                      source="shipping_address.country"
                      fontSize="16px"
                    />
                    <span>
                      Tel:{" "}
                      <TextField
                        source="shipping_address.phone"
                        fontSize="16px"
                      />
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Order Details end */}

          {/* Payment Details start */}
          <Box
            sx={{ maxWidth: 1000 }}
            style={{ border: "1px solid", marginTop: "20px" }}
          >
            <Box>
              {/* Inside Row Box */}
              <Box width="100%" display="flex" flexDirection="row">
                {/* 1st Row */}
                <Box width="100%" display="flex" flexDirection="column">
                  <Typography
                    component="legend"
                    // marginTop="20px"
                    padding="10px"
                    fontWeight="500"
                    marginBottom="10px"
                    // border="1px solid"
                    borderBottom="1px solid"
                    borderRight="2px solid"
                    fontSize="18px"
                    backgroundColor="#dddddd"
                  >
                    Payment Method:
                  </Typography>
                  <Box style={{ padding: "10px" }}>
                    <p>Online payment</p>
                  </Box>
                </Box>
                {/* 2nd Row */}
                <Box width="100%" display="flex" flexDirection="column">
                  <Typography
                    component="legend"
                    padding="10px"
                    fontWeight="500"
                    marginBottom="10px"
                    borderBottom="1px solid"
                    // borderTop="1px solid"
                    fontSize="18px"
                    backgroundColor="#dddddd"
                  >
                    Shipping Method:
                  </Typography>
                  <Box
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p>Fixed - Fixed Rate</p>
                    <p>(Total Shipping Charges ₹25.00)</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Payment Details end */}

          <table style={{ marginTop: "20px" }}>
            <tr>
              <th>Products</th>
              <th>SKU</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Tax</th>
              <th>Subtotal</th>
            </tr>
            {tableData.map((data, i) => (
              <tr key={i + 1}>
                <td>{data.name}</td>
                <td>{data.sku}</td>
                <td>₹{data.price}</td>
                <td>{data.quantity}</td>
                <td>₹0.00</td>
                <td>₹{data.quantity * data.price}</td>
              </tr>
            ))}
          </table>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginTop: "50px",
              fontWeight: "bold",
              padding: "20px",
            }}
          >
            <p
              style={{
                margin: "0px",
                padding: "2px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              subtotal:{" "}
              <span style={{ marginLeft: "30px" }}>
                ₹{order.current_subtotal_price}
              </span>{" "}
            </p>
            <p
              style={{
                margin: "0px",
                padding: "2px",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "25px",
              }}
            >
              Shipping & Handling:{" "}
              <span style={{ marginLeft: "30px" }}>
                {" "}
                ₹{order.current_total_tax}
              </span>
            </p>
            <p
              style={{
                margin: "0px",
                padding: "2px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              Estimated Total:{" "}
              <span style={{ marginLeft: "30px" }}>
                {" "}
                ₹{order.current_subtotal_price}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderEditPdfForm;
