import * as React from "react";
import { TextField } from "react-admin";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./orderedit.css";
import moment from "moment";
import saveAs from "save-as";
import JSZip from "jszip";
var jszip = require("jszip");
const zip = new jszip();
const ComponentToPrint = React.forwardRef((printData, ref) => {
  const formattedAmount = (number) => {
    const parsedAmount = parseFloat(number);
    const formattedValue = parsedAmount.toFixed(2);
    const parts = formattedValue.toString().split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `₹${integerPart}.${parts[1]}`;
  };
  return (
    <>
      <div style={{ display: "none" }}>
        {printData.printData?.length > 0 ? (
          <div ref={ref} style={{ padding: "10px" }}>
            {printData.printData?.length &&
              printData.printData.map((item) => (
                <>
                  {/* Order Details start */}

                  <Box
                    sx={{ maxWidth: 1000 }}
                    style={{
                      border: "1px solid",
                      minHeight: "1px",
                      pageBreakBefore: "always",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "#6b6969",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        Order # <TextField source="order_number" />{" "}
                        <span>{item.order_number}</span>
                      </Typography>
                      <Typography>
                        Order Date:{" "}
                        <span>
                          {moment(item.createdAt).format("DD MMM YYYY")}
                        </span>
                      </Typography>
                    </Box>
                    <Box>
                      {/* Inside Row Box */}
                      <Box width="100%" display="flex" flexDirection="row">
                        {/* 1st Row */}
                        <Box width="100%" display="flex" flexDirection="column">
                          <Typography
                            component="legend"
                            padding="10px"
                            fontWeight="500"
                            marginBottom="10px"
                            borderBottom="1px solid"
                            borderRight="2px solid"
                            fontSize="20px"
                            backgroundColor="#dddddd"
                          >
                            Sold to:
                          </Typography>
                          <Box style={{ padding: "10px" }}>
                            <Typography>
                              <span>Name: </span>{item?.customer?.default_address?.name || item?.shipping_address?.name}
                            </Typography>
                            <Typography>
                            <span>Address1: </span>{item?.customer?.default_address?.address1 || item?.shipping_address?.address1}
                            </Typography>
                           { item?.customer?.default_address?.address2 ? <Typography>
                            <span>Address2: </span>{item?.customer?.default_address?.address2}
                            </Typography> : <Typography>
                            <span>Address2: </span>{item?.shipping_address?.address2}
                            </Typography>
                            }
                            <div style={{ display: "inline-block" }}>
                              <Typography>
                                {item?.customer?.default_address?.city || item?.shipping_address?.city},{" "}
                                {item?.customer?.default_address?.province || item?.shipping_address?.province},{" "}
                                {item?.customer?.default_address?.zip || item?.shipping_address?.zip}
                              </Typography>
                            </div>
                            <Typography>
                              {item?.customer?.default_address?.country || item?.shipping_address?.country}
                            </Typography>
                            <div>
                              <Typography>
                                T:{item?.customer?.default_address?.phone || item?.shipping_address?.phone}
                              </Typography>
                              <Typography>
                              Customer phone:{item.customer.phone}
                            </Typography>
                            </div>
                          </Box>
                          
                        </Box>
                        {/* 2nd Row */}
                        <Box width="100%" display="flex" flexDirection="column">
                          <Typography
                            component="legend"
                            padding="10px"
                            fontWeight="500"
                            marginBottom="10px"
                            borderBottom="1px solid"
                            // borderTop="1px solid"
                            fontSize="20px"
                            backgroundColor="#dddddd"
                          >
                            Ship to:
                          </Typography>
                          <Box style={{ padding: "10px" }}>
                            <Typography>
                            <span>Name: </span>{item.shipping_address.name}
                            </Typography>
                            {<Typography>
                              <span>Address1: </span>{item.shipping_address.address1}
                            </Typography>}
                           {item.shipping_address.address2 && <Typography>
                              <span>Address2: </span>{item.shipping_address.address2}
                            </Typography>}
                            <div style={{ display: "inline-block" }}>
                              <Typography>
                                {item.shipping_address.city},{" "}
                                {item.shipping_address.province},{" "}
                                {item.shipping_address.zip}
                              </Typography>
                            </div>
                            <Typography>
                              {item.shipping_address.country}
                            </Typography>
                            <Typography>
                              T:{item.shipping_address.phone}
                            </Typography>
                            <Typography>
                              Customer phone:{item.customer.phone}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* Order Details end */}

                  {/* Payment Details start */}
                  <Box
                    sx={{ maxWidth: 1000 }}
                    style={{ border: "1px solid", marginTop: "20px" }}
                  >
                    <Box>
                      {/* Inside Row Box */}
                      <Box width="100%" display="flex" flexDirection="row">
                        {/* 1st Row */}
                        <Box width="100%" display="flex" flexDirection="column">
                          <Typography
                            component="legend"
                            padding="10px"
                            fontWeight="500"
                            marginBottom="10px"
                            borderBottom="1px solid"
                            borderRight="2px solid"
                            fontSize="20px"
                            backgroundColor="#dddddd"
                          >
                            Payment Method:
                          </Typography>
                          <Box style={{ padding: "10px" }}>
                            <Typography>{item?.gateway}</Typography>
                          </Box>
                        </Box>
                        {/* 2nd Row */}
                        <Box width="100%" display="flex" flexDirection="column">
                          <Typography
                            component="legend"
                            padding="10px"
                            fontWeight="500"
                            marginBottom="10px"
                            borderBottom="1px solid"
                            fontSize="20px"
                            backgroundColor="#dddddd"
                          >
                            Shipping Method:
                          </Typography>
                          <Box
                            style={{
                              padding: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {
                              item?.customer?.fulfillmentService && <Typography style={{marginBottom:"10px"}}>{item?.customer?.fulfillmentService}</Typography>
                            }
                            <Typography>
                              Total Shipping Charges: {formattedAmount(item?.shipment)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* Payment Details end */}

                  <table style={{ marginTop: "20px" }}>
                    <tr>
                      <th>Products</th>
                      <th style={{ textAlign: "right", paddingRight: "25px" }}>
                        SKU
                      </th>
                      <th>Price</th>
                      <th style={{ textAlign: "right" }}>Quantity</th>
                      <th style={{ textAlign: "right" }}>Tax</th>
                      <th style={{ textAlign: "right" }}>Subtotal</th>
                    </tr>
                    {JSON.parse(item.line_items).map((data) => (
                      <tr key={data.id}>
                        <td>{data.name}</td>
                        <td
                          style={{ textAlign: "right", paddingRight: "25px" }}
                        >
                          {data.sku}
                        </td>
                        <td style={{ textAlign: "right" }}>₹{data.price}</td>
                        <td style={{ textAlign: "right" }}>{data.quantity}</td>
                        <td style={{ textAlign: "right" }}>₹0.00</td>
                        <td style={{ textAlign: "right" }}>
                          ₹{data.quantity * data.price}
                        </td>
                      </tr>
                    ))}
                  </table>
                  {/* <p>{item.line_items}</p> */}

                  <div
                    style={{
                      display: "flex",
                      paddingRight: "10px",
                      marginTop: "100px",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "40%",
                      }}
                    >
                      <div style={{ textAlign: "right" }}>
                        <Typography style={{ fontWeight: "700" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                width: "180px",
                                display: "inline-block",
                              }}
                            >
                              Subtotal:
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              {/* ₹{item.current_subtotal_price} */}
                              {/* ₹{(parseFloat(item.current_subtotal_price) + parseFloat(item.discount)).toFixed(2)} */}
                    {formattedAmount(parseInt(item?.current_subtotal_price) + parseInt(item?.discount))}

                            </span>
                          </div>
                        </Typography>
                        {
                          item?.discount > 0 && 
                          <Typography style={{ fontWeight: "700" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                width: "180px",
                                display: "inline-block",
                              }}
                            >
                              Coupon Discount:
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              {formattedAmount(item.discount)}
                            </span>
                          </div>
                        </Typography>
                        }
                        <Typography style={{ fontWeight: "700" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                width: "180px",
                                display: "inline-block",
                              }}
                            >
                              Shipping & Handling:
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              {formattedAmount(item.shipment)}
                            </span>
                          </div>
                        </Typography>
                        <Typography style={{ fontWeight: "700" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                width: "180px",
                                display: "inline-block",
                              }}
                            >
                              Estimated Total:
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              {formattedAmount(item.total_price)}
                            </span>
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        ) : null}
      </div>
    </>
  );
});

export default ComponentToPrint;

// export const CsvFile = ({ orders }) => {
//   // csv func
//   console.log("zip export order", orders);
//   function exportToCsv() {
//     if (orders && orders.length > 0) {
//       orders = orders.map(function (o) {
//         // o.line_items = JSON.parse(o.line_items);
//         // console.log("o.line_items", o.line_items);
//         let csvData = jsonToCsv(
//           JSON.parse(o.line_items).map((lItem) => {
//             return {
//               sku: lItem.sku,
//               quantity: lItem.quantity,
//             };
//           })
//         );
//         console.log("Order ID", o.order_number);
//         console.table("csvData11", csvData);
//         zip.file(`order-export-${o.order_number}.csv`, csvData);
//         console.log("oooooooo", o);
//         return o;
//       });
//       zip.generateAsync({ type: "blob" }).then(function (content) {
//         // see FileSaver.js
//         const fileName = `${moment().format("YYYYMMDDHHmmss")}.zip`;
//         saveAs(content, fileName);
//       });
//     }
//   }
//   function jsonToCsv(items) {
//     console.log("export item", items);
//     const header = Object.keys(items[0]);
//     // handle null or undefined values here
//     const replacer = (key, value) => value ?? "";

//     const rowItems = items.map((row) =>
//       header
//         .map((fieldName) => JSON.stringify(row[fieldName], replacer))
//         .join(",")
//     );
//     const csv = [...rowItems].join("\r\n");
//     return csv;
//   }

//   return <Button onClick={() => exportToCsv()}>EXPORT CSV</Button>;
// };

export const CsvFile = ({ orders }) => {
  function exportToCsv() {
    if (orders && orders.length > 0) {
      const zip = new JSZip();
      orders.forEach((o) => {
        const lineItems = JSON.parse(o.line_items);
        const csvData = jsonToCsv(
          lineItems.map((lItem) => {
            return {
              sku: lItem.sku,
              quantity: lItem.quantity,
            };
          })
        );
        zip.file(`order-export-${o.order_number}.csv`, csvData.replaceAll('"', ''));
      });

      zip.generateAsync({ type: "blob" }).then(function (content) {
        const fileName = `${moment().format("YYYYMMDDHHmmss")}.zip`;
        saveAs(content, fileName);
      });
    }
  }

  function jsonToCsv(items) {
    const header = Object.keys(items[0]);
    const replacer = (key, value) => value ?? "";
    const rowItems = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    return rowItems.join("\r\n");
  }

  return <Button onClick={exportToCsv}>EXPORT CSV</Button>;
};