import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { StoreAdmin } from "./routes/StoreAdmin";
import "./App.css";

const App = () => {
  const token  = JSON.parse(localStorage.getItem("auth")) || [];
  return (
    <BrowserRouter>
      <Routes>
        {!token.accessToken && (
          <Route path="/" element={<Navigate to="/admin/login" />} />
        )}
        <Route path="/" element={<StoreAdmin />} />
        <Route path="/admin/*" element={<StoreAdmin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
